<template>
  <v-container fluid class="pa-6">
    <!-- filter panel -->
    <v-row>
      <GsDataFilter
        ref="dataFilter"
        :appSetting="appSettingKey"
        :filters="filters"
        v-on:changed="onFilterChanged"
      />
    </v-row>
    <!-- table -->
    <v-row>
      <v-container fluid class="ma-0 pa-0">
        <v-card>
          <GsDataTable
            ref="table"
            api=""
            endpoint="/jobs"
            :appSetting="appSettingKey"
            :headers="headers"
            :sort-by="['createdAt']"
            :sort-desc="[true]"
            :beforeCallApi="beforeCallApi"
            :afterCallApi="afterCallApi"
            @click:row="row_click"
          >
            <!-- <template v-slot:item.details="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    icon
                    small
                    :to="{
                      name: 'Report Log Details',
                      params: { jobId: item.jobId },
                    }"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi mdi-text-box-search-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("details") }}</span>
              </v-tooltip>
            </template> -->
            <template v-slot:item.actions="{ item }">
              <GsActionsMenu
                :actions="actions"
                :onActivate="(actionId) => action_activate(actionId, item)"
              />
            </template>

            <template v-slot:item.createdAt="{ item }">
              <span>
                {{ item.createdAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$createdBy | empty }})
              </span>
            </template>

            <!-- <template v-slot:item.updatedAt="{ item }">
              <span v-if="item.updatedAt">
                {{ item.updatedAt | getDisplayDate }}
                <br />
                ({{ item.$calculated.$updatedBy | empty }})
              </span>
            </template> -->

            <template v-slot:item.status="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark>{{
                $t(item.status.split(".").pop())
              }}</v-chip>
            </template>

            <template v-slot:item.queueId="{ item }">
              <div v-if="!item.queueId">
                <v-icon small color="blue darken-2" class="mr-2">
                  mdi mdi-hand-back-right
                </v-icon>
                <span class="caption text-uppercase">{{ $t("manually") }}</span>
              </div>

              <!-- <v-btn v-if="!item.queueId" text disabled class="pl-0">
                <v-icon small color="green darken-2" class="mr-2">
                  mdi mdi-hand-right
                </v-icon>
                <span class="caption">{{ $t("manually") }}</span>
              </v-btn> -->

              <v-btn
                v-if="item.queueId"
                small
                @click="scheduler_click(item, $event)"
              >
                <v-icon small color="blue darken-2" class="mr-2">
                  mdi mdi-clock
                </v-icon>
                <span class="caption">{{ $t("scheduler") }}</span>
              </v-btn>

              <!-- <v-btn
                v-if="item.queueId"
                :disabled="!item.$calculated.task"
                text
                :to="{
                  name: 'Scheduled Report Details',
                  params: { taskId: item.$calculated.task.taskId },
                }"
                class="pl-0"
              >
                <v-icon small color="blue darken-2" class="mr-2"
                  >mdi mdi-clock</v-icon
                >
                <span class="caption">{{ $t("scheduler") }}</span>
              </v-btn> -->
            </template>

            <template v-slot:footer.page-text="props">
              {{ props.pageStart }} - {{ props.pageStop }} /
              {{ props.itemsLength }}
            </template>
          </GsDataTable>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Jobs",
  components: {
    GsDataTable,
    GsActionsMenu,
    GsDataFilter,
  },
  data() {
    return {
      appSettingKey: "jobs",
      headers: [
        {
          text: this.$t("actions"),
          value: "actions",
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("template"),
          value: "$calculated.$templateName",
          width: 200,
          sortable: false,
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: 150,
          sortable: true,
        },
        // {
        //   text: this.$t("updatedAt"),
        //   value: "updatedAt",
        //   width: 150,
        //   sortable: true
        // },
        {
          text: this.$t("status"),
          value: "status",
          width: 100,
          sortable: true,
          align: "center",
        },
        {
          text: this.$t("generatedBy"),
          value: "queueId",
          width: 200,
          sortable: false,
        },
      ],
      initialized: false,
      actions: ["details"],
      filters: [
        {
          field: "templateId",
          component: "GsTreeSelectFilter",
          params: {
            options: [],
          },
          width: "wider",
        },
        {
          field: "createdBy",
          component: "GsSelectFilter",
          params: {
            options: [],
          },
        },
        {
          field: "status",
          component: "GsSelectFilter",
          params: {
            options: this.$store.state.lookups.job_status.map((p) => ({
              value: p,
              text: this.$t(p.split(".").pop()),
            })),
          },
        },
        {
          field: "queueId",
          component: "GsSelectFilter2",
          params: {
            options: [
              { value: "ne:\0", text: this.$t("scheduler") },
              { value: "eq:\0", text: this.$t("manually") },
            ],
          },
        },
      ],
    };
  },
  async mounted() {
    this.initialized = false;
    await this.fillTemplateIdOptions();
    await this.fillCreatedByOptions();
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      // params.url += `&ownedBy=${encodeURIComponent(
      //   this.$store.state.user.ownedBy
      // )}`;
      params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      const templateIds = params.items.map((p) => p.templateId);
      const templateNames = await this.getTemplateNamesByIds(templateIds);
      const queueIds = params.items.map((p) => p.queueId).filter((p) => p);
      const taskIds = await this.getTaskIdsByQueueIds(queueIds);
      const taskIdsHash = this.arrToHash(taskIds, "queueId");
      // console.log(taskIdsHash)
      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$templateName = templateNames[item.templateId];
        item.$calculated.$createdBy = item?.createdBy?.name || "";
        item.$calculated.$updatedBy = item?.updatedBy?.name || "";
        item.$calculated.taskId = taskIdsHash[item.queueId]
          ? taskIdsHash[item.queueId].taskId
          : null;
      }
      return params;
    },
    details_click(item) {
      this.$router.push_safe({
        name: "Report Log Details",
        params: { jobId: item.jobId },
      });
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "details":
          this.details_click(item);
          break;
      }
    },
    row_click(item) {
      this.details_click(item);
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async fillTemplateIdOptions() {
      const url = `/templates?fields=templateId,name,tags,format&limit=100`;
      const { data } = await callBffAPI({ url, method: "GET", cache: true });
      if (!data) return;

      const templateId_filter = this.filters.find(
        (p) => p.field === "templateId"
      );

      templateId_filter.params.options = this.getTemplateTypeItems(data);
    },
    async fillCreatedByOptions() {
      const data = await this.getUsers();
      if (!data) return;
      this.filters.find((p) => p.field === "createdBy").params.options =
        data.map((p) => ({ value: p._uri, text: p.name }));
    },
    async scheduler_click(item, event) {
      event.stopPropagation();
      // console.log(item)

      // const queue = await this.getTask(item.queueId);
      // const taskId = queue.taskId

      const taskId = item.$calculated.taskId;
      if (taskId == null) return;
      this.$router.push_safe({
        name: "Scheduled Report Details",
        params: { taskId },
      });
    },
  },
};
</script>
